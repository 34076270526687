import { storeToRefs, defineStore } from 'pinia'
import { ref } from 'vue'
import providersRef from '@/assets/providers.json'
import categoriesRef from '@/assets/categories.json'
import stationTypeRef from '@/assets/stationType.json'

export const useFilterStore = defineStore('filter', () => {
  const nearByMap = ref({})
  const typeMap = ref({})
  const providersMap = ref({})
  const filterAc = ref(false)
  const filterDc = ref(true)
  const providers = ref(providersRef)
  const providersFiltered = ref(Object.keys(providers))
  const showOnlyRecommended = ref(false)
  const nearByCategoriesFiltered = ref(categoriesRef)
  const stationTypeFiltered = ref(stationTypeRef)
  const maximumChargeLimit = ref(80)
  const minimumBattery = ref(20)
  const showCheckin = ref(true)
  const sortType = ref('distance')
  const isCleanedState = ref(false)
  const $reset = () => {
    isCleanedState.value = true
    nearByMap.value = {}
    providersMap.value = {}
    typeMap.value = {}
    filterAc.value = true
    filterDc.value = true
    providers.value = providersRef
    providersFiltered.value = Object.keys(providers.value)
    showOnlyRecommended.value = false
    nearByCategoriesFiltered.value = categoriesRef
    stationTypeFiltered.value = stationTypeRef
    showCheckin.value = true
  }

  const selectAllNearBy = () => {
    nearByCategoriesFiltered.value = categoriesRef
  }

  const selectAllType = () => {
    stationTypeFiltered.value = stationTypeRef
  }

  const selectAllProviders = () => {
    providersFiltered.value = Object.keys(providersRef)
  }

  const changeProviderFilter = (providers) => {
    providersFiltered.value = providers
  }
  const changeBetteryControl = (betteryControl) => {
    maximumChargeLimit.value = betteryControl.maximumChargeLimit
    minimumBattery.value = betteryControl.minimumBattery
  }
  const filterRecommended = (value) => {
    window.requestAnimationFrame(() => {
      showOnlyRecommended.value = value
    })
  }

  return { typeMap, isCleanedState, selectAllType, stationTypeFiltered, sortType, showCheckin, selectAllProviders, selectAllNearBy, $reset, nearByMap, nearByCategoriesFiltered, providersMap, filterAc, filterDc, providersFiltered, showOnlyRecommended, maximumChargeLimit, minimumBattery, changeProviderFilter, changeBetteryControl, filterRecommended }
}, {
  persist: { enabled: true, exclude: ['providersFiltered'] }
})

export function useFilterStoreRefs () {
  const filterStore = useFilterStore()
  return storeToRefs(filterStore)
}
